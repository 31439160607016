enum signatureFieldType {
  TEXT_FIELD = 1,
  STAMP_FIELD = 2,
  CHECKBOX_FIELD = 3,
}

enum signXIDStatus {
  CLAIMED = "claimed",
  CODE_SUCCEED = "code_succeed",
  SIGNED = "signed",
}

export default {
  signatureFieldType,
  signXIDStatus,
};
