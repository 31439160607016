
import { defineComponent, onMounted, reactive, toRefs, watch } from "vue";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist-sig";

import {
  PDFDocumentProxy,
  PDFPageProxy,
} from "node_modules/pdfjs-dist-sig/types/display/api";
import PdfjsWorker from "worker-loader!pdfjs-dist-sig/es5/build/pdf.worker.js";

if (typeof window !== "undefined" && "Worker" in window) {
  GlobalWorkerOptions.workerPort = new PdfjsWorker();
}

import { storage, key } from "@/lib/storage";

export default defineComponent({
  name: "PdfViewer",
  props: {
    src: { type: String, default: "" },
    page: { type: Number, default: 1 },
    scaleFitWidth: { type: Boolean, default: false },
    fileId: { type: String, default: "" },
  },
  emits: ["loaded", "pageLoaded", "pageNums", "pageScaled", "pageSized"],
  setup(props, context) {
    var pdf: unknown = null;
    const state = reactive({
      pdf: pdf,
      renderedPage: props.page,
    });

    const localStorage = storage.getLocalStorage();
    const sessionId = localStorage.get(key.sessionId);
    const loadingTask = getDocument({
      url: props.src,
      httpHeaders: { Session: sessionId },
      withCredentials: true,
      cMapUrl: "/cmaps/",
      cMapPacked: true,
    });

    onMounted(() => {
      loadingTask.promise.then((pdfFile: PDFDocumentProxy) => {
        state.pdf = pdfFile;
        context.emit("loaded");
        context.emit("pageNums", pdfFile.numPages);
        pdfFile.getPage(props.page).then((pdfPage) => {
          renderPdf(
            pdfPage,
            props.page,
            props.scaleFitWidth,
            props.fileId
          ).then(({ pageNum, scale, width, height }) => {
            context.emit("pageLoaded", pageNum);
            context.emit("pageScaled", scale);
            context.emit("pageSized", { width, height });
          });
        });
      });
    });

    watch(
      () => props.page,
      (page) => {
        const pdfFile = state.pdf as PDFDocumentProxy;
        if (pdfFile == null) return;
        state.renderedPage = page;
        pdfFile.getPage(page).then((pdfPage: PDFPageProxy) => {
          renderPdf(
            pdfPage,
            props.page,
            props.scaleFitWidth,
            props.fileId
          ).then((pageInfo) => {
            context.emit("pageLoaded", pageInfo.pageNum);
            context.emit("pageScaled", pageInfo.scale);
          });
        });
      }
    );

    watch(
      () => props.src,
      (src) => {
        const loadingTask = getDocument(src);
        loadingTask.promise.then((pdfFile: PDFDocumentProxy) => {
          state.pdf = pdfFile;
          state.renderedPage = 1;
          context.emit("loaded");
          context.emit("pageNums", pdfFile.numPages);

          pdfFile.getPage(state.renderedPage).then((pdfPage) => {
            const id = "pdf-canvas" + props.fileId;
            const canvas = document.getElementById(id) as HTMLCanvasElement;
            renderPdf(
              pdfPage,
              state.renderedPage,
              props.scaleFitWidth,
              props.fileId
            ).then((pageInfo) => {
              context.emit("pageLoaded", pageInfo.pageNum);
              context.emit("pageScaled", pageInfo.scale);
            });
          });
        });
      }
    );

    return {
      ...toRefs(state),
    };
  },
});

function renderPdf(
  page: PDFPageProxy,
  pageNum: number,
  scaleFitWidth: boolean,
  fileId: string
): Promise<any> {
  const canvasId = "pdf-canvas" + fileId;
  const overlayId = "canvas-overlay" + fileId;
  const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
  const overlay = document.getElementById(overlayId) as HTMLDivElement;
  const context = canvas.getContext("2d");
  // canvas.width = 0;
  // canvas.height = 0;
  canvas.style.width = "100%";
  canvas.style.height = "100%";
  overlay.style.width = "100%";
  overlay.style.height = "100%";

  const defaultViewport = page.getViewport({ scale: 1 });
  var scale = 1;
  if (scaleFitWidth) {
    scale = canvas.clientWidth / defaultViewport.width;
  } else {
    const scaleWidth = canvas.clientWidth / defaultViewport.width;
    const scaleHeight = canvas.clientHeight / defaultViewport.height;
    scale = scaleWidth < scaleHeight ? scaleWidth : scaleHeight;
  }
  const viewport = page.getViewport({ scale: scale });

  canvas.height = Math.floor(viewport.height);
  canvas.width = Math.floor(viewport.width);

  canvas.style.width = Math.floor(viewport.width) + "px";
  canvas.style.height = Math.floor(viewport.height) + "px";
  overlay.style.width = Math.floor(viewport.width) + "px";
  overlay.style.height = Math.floor(viewport.height) + "px";

  var renderContext = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    canvasContext: context!,
    viewport: viewport,
  };
  return page.render(renderContext).promise.then(() => ({
    pageNum,
    scale,
    width: defaultViewport.width,
    height: defaultViewport.height,
  }));
}
