import { useRouter } from "vue-router";
import SignEnum from "@/models/signEnum";
import { storage } from "../storage";
import { FileInput } from "@/models/contracts/FileInput";

const router = useRouter();
const localStorage = storage.getLocalStorage();

export default function useSignFunctions(): {
  getSignedContractVarsConclusion: (contractVars: any) => Array<any>;
  getNumberOfAlreadySignMember: (contractSigners: any) => number;
  getAllFieldNeedToBeSignByUser: (
    contractFiles: any,
    order: number
  ) => Array<any>;
  getNeedToBeSignSignatureField: (fields: any, order: number) => Array<any>;
  getOtherSignerVars: (contractFiles: any, order: number) => Array<any>;
  getFileMappingIdAndName: (files: any) => Array<any>;
  getSignOrder: (allSigners: any, profileId: string) => number;
  getSignerByEmail: (allSigners: any, email: string) => Record<string, unknown>;
  haveBeenNotBeSigned: (field: any) => boolean;
  signFileSuccess: (
    fileId: string,
    fileList: Array<any>,
    contractId: string,
    isGuest: boolean
  ) => void;
  navigateToSignDone: (contractId: string, isGuest: boolean) => void;
  saveAccessCode: (contractId: string, accessCode: string) => boolean;
  getAccessCode: (contractId: string) => string;
  removeAccessCode: (contractId: string) => boolean;
  onInputField: (
    value: any,
    fieldId: string,
    fieldList: Array<any>,
    callback: CallableFunction
  ) => void;
  saveVarFieldToLocalStorage: (field: any, contractId: string) => void;
} {
  const getSignedContractVarsConclusion = (contractVars: any) => {
    const conclusionResultVars = [] as any[];

    contractVars.forEach((field: any) => {
      const extrasData = getNeccessaryDataFromVar(field);
      conclusionResultVars.push(extrasData);
    });

    return conclusionResultVars;
  };

  const getNeccessaryDataFromVar = (field: any) => {
    switch (field.type) {
      case SignEnum.signatureFieldType.TEXT_FIELD:
        return {
          base64: field.base64 ?? "",
          type: field.type,
          id: field.id,
          text: field.text,
        };
      case SignEnum.signatureFieldType.STAMP_FIELD: {
        const stampField = FileInput.fromJson(field)
        return {
          ...stampField.toJson(field.scale),
          type: field.type,
          id: field.id,
          text: !field.isImprint ? field.text : "",
          imprintType: field.imprintType,
        };
      }
      case SignEnum.signatureFieldType.CHECKBOX_FIELD:
        return {
          base64: field.base64 ?? "",
          type: field.type,
          id: field.id,
          isChecked: field.isChecked,
        };
      default:
        return {
          type: field.type,
          id: field.id,
        };
    }
  };

  const getNumberOfAlreadySignMember = (contractSigners: any = []) => {
    const numberOfSigner = contractSigners.filter(
      (signer: any) => signer.statusName === "signed"
    ).length;

    return numberOfSigner;
  };

  const getAllFieldNeedToBeSignByUser = (contractFiles: any, order: number) => {
    let fieldNeedToBeSigned = [] as any[];
    contractFiles.forEach((file: any) => {
      if (!file.signed) {
        const accountNeedToSignField = getNeedToBeSignSignatureField(
          file.contractVars ?? [],
          order
        );

        fieldNeedToBeSigned = fieldNeedToBeSigned.concat(
          accountNeedToSignField
        );
      }
    });

    return fieldNeedToBeSigned;
  };

  const getNeedToBeSignSignatureField = (fields: any = [], order: number) => {
    const needToBeSignedSignatureField = fields.filter(
      (item: any) => item.contractSignerOrder === order
    );
    return needToBeSignedSignatureField;
  };

  const getOtherSignerVars = (contractFiles: any, order: number) => {
    let otherSignerVars = [] as any[];
    contractFiles.forEach((file: any) => {
      const eachFileVars = file.contractVars
        ? file.contractVars.filter(
            (field: any) => field.contractSignerOrder > order
          )
        : [];

      otherSignerVars = otherSignerVars.concat(eachFileVars);
    });

    return otherSignerVars;
  };

  const getFileMappingIdAndName = (files: any) => {
    const fileMapping = files.map((file: any) => ({
      id: file.id,
      name: file.fileName,
    }));

    return fileMapping;
  };

  const getSignOrder = (allSigners: any, profileId: string) => {
    const signer = allSigners.find(
      (signer: any) => signer.userId === profileId
    );

    return signer ? signer.order ?? 0 : 0;
  };

  //for sign-guest
  const getSignerByEmail = (allSigners: any, email: string) => {
    const signer = allSigners.find(
      (signer: any) => signer.signerEmail === email
    );
    return signer;
  };

  const haveBeenNotBeSigned = (field: any) => {
    return (
      !field.isFilled &&
      (field.type !== SignEnum.signatureFieldType.CHECKBOX_FIELD ||
        !field.isOptionalCheckbox)
    );
  };
  // access code
  const saveAccessCode = (contractId: string, accessCode: string) => {
    return localStorage.add(contractId, accessCode);
  };
  const removeAccessCode = (contractId: string) => {
    return localStorage.remove(contractId);
  };
  const getAccessCode = (contractId: string) => {
    return localStorage.get(contractId);
  };
  // for signing  field
  const onInputField = (
    value: any,
    fieldId: string,
    fieldList: Array<any>,
    callback: CallableFunction
  ) => {
    const field = fieldList.find((item: any) => item.id === fieldId);
    const { TEXT_FIELD, CHECKBOX_FIELD } = SignEnum.signatureFieldType;
    switch (field.type) {
      case TEXT_FIELD: {
        field.text = value;
        field.isFilled = field.text.trim() !== "";
        break;
      }
      case CHECKBOX_FIELD: {
        field.isChecked = value;
        field.isFilled = value;
        break;
      }
    }

    callback(fieldList);
  };

  //save var field to localstorage
  const saveVarFieldToLocalStorage = (fields: any, contractId: string) => {
    const fieldInformations = fields.map((field: any) => ({
      isChecked: field.isChecked || false,
      id: field.id,
      isFilled: field.isFilled || false,
      text: field.text || "",
      base64: field.base64 || "",
      contractFileId: field.contractFileId,
      width: field.width,
      height: field.height,
      positionX: field.positionX,
      positionY: field.positionY,
      imprintType: field.imprintType,
      isSaved: true,
      scale: field.scale || 1
    }));

    const fieldInformationToString = JSON.stringify(fieldInformations);
    localStorage.add(contractId + "-vars", fieldInformationToString);
  };

  //for sign XID
  const signFileSuccess = (
    fileId: string,
    fileList: Array<any>,
    contractId: string,
    isGuest: boolean
  ) => {
    (fileList.find((item: any) => item.id === fileId) || {}).isSign = true;
    const isContinueSigning = fileList.every((file: any) => file.isSign);
    if (!isContinueSigning) navigateToSignDone(contractId, isGuest);
  };

  const navigateToSignDone = (contractId: string, isGuest: boolean) => {
    router.replace({
      name: isGuest ? "GuestSignDone" : "SignDone",
      query: {
        contractId: contractId,
      },
    });
  };
  return {
    getSignedContractVarsConclusion,
    getNumberOfAlreadySignMember,
    getAllFieldNeedToBeSignByUser,
    getNeedToBeSignSignatureField,
    getFileMappingIdAndName,
    getOtherSignerVars,
    getSignOrder,
    getSignerByEmail,
    haveBeenNotBeSigned,
    signFileSuccess,
    navigateToSignDone,
    saveAccessCode,
    removeAccessCode,
    getAccessCode,
    onInputField,
    saveVarFieldToLocalStorage,
  };
}
