
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useField } from "vee-validate";

export default defineComponent({
  name: "Dropdown",
  props: {
    options: { type: Array },
    defaultOption: { type: String },
    placeHolder: { type: String },
    name: { type: String, default: "" },
    rules: { default: "" },
    value: { type: String },
    isSmallDropdown: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  emits: ["onChange", "update:value"],
  setup(props: any, context: any) {
    const { value: selectedId } = useField(props.name, props.rules, {
      initialValue: props.defaultOption,
    });

    const selectedIndex = ref(-1);

    const selectedText = computed(() =>
      selectedIndex.value < 0
        ? props.placeHolder
        : (props.options as Array<any>)[selectedIndex.value]?.name
    );

    const onValueChanged = (idx: number) => {
      selectedIndex.value = idx;
      const id = (props.options as Array<any>)[idx]?.id;
      selectedId.value = id;
      context.emit("onChange", id);
      context.emit("update:value", id);
    };

    watch(
      () => props.defaultOption,
      (val) => {
        selectedId.value = val;
        selectedIndex.value = props.options?.findIndex(
          (item: any) => item.id === val
        );
      }
    );

    watch(
      () => props.value,
      (val) => {
        selectedId.value = val;
        selectedIndex.value = props.options?.findIndex(
          (item: any) => item.id === val
        );
      }
    );

    onMounted(() => {
      if (props.defaultOption) {
        selectedIndex.value = props.options?.findIndex(
          (item: any) => item.id === props.defaultOption
        );
      } else if (!props.placeHolder) {
        selectedIndex.value = 0;
      }
    });

    return {
      selectedId,
      selectedIndex,
      selectedText,
      onValueChanged,
    };
  },

  mounted() {
    window.addEventListener("click", this.clickOutside);
  },
  deactivated() {
    window.removeEventListener("click", this.clickOutside);
  },

  methods: {
    openDropdown() {
      this.$el.classList.toggle("open");
      this.$el.querySelector(".custom-options")?.classList.toggle("open");
    },

    clickOutside(e: any) {
      if (!this.$el.contains(e.target as Node)) {
        this.$el
          .querySelectorAll(".custom-options")
          .item(0)
          .classList.remove("open");
        this.$el.classList.remove("open");
      }
    },
  },
});
