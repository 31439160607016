
import {
  defineComponent,
  watch,
  reactive,
  toRefs,
  computed,
  inject,
} from "vue";
import PdfViewer from "@/components/atomics/pdf-viewer/PdfViewer.vue";
import Dropdown from "@/components/atomics/Dropdown.vue";
import TextButton from "@/components/atomics/TextButton.vue";

export default defineComponent({
  name: "FilePreview",
  components: {
    PdfViewer,
    Dropdown,
    TextButton,
  },
  props: {
    files: { type: Array, default: () => [] },
    selectedIdx: { type: Number, default: () => 0 },
    isScale: { type: Boolean, default: false },
    canRemove: { type: Boolean, default: true },
    isPadding: { type: Boolean, default: true },
  },
  emits: ["update:files", "update:selectedIdx"],
  setup(props) {
    // const pdfView = ref(null);
    const state = reactive({
      page: 1,
      numPages: 1,
      pageReady: false,
      fileList: props.files as any[],
    });

    const isMobile = inject("isMobile");

    const isPdf = computed(() => {
      return state.fileList[props.selectedIdx]?.type.indexOf("pdf") >= 0;
    });

    const isDocx = computed(() => {
      return state.fileList[props.selectedIdx]?.name.indexOf("doc") >= 0;
    });

    watch(
      () => props.files,
      (val) => {
        state.fileList = val;
      }
    );

    return {
      ...toRefs(state),
      isPdf,
      isDocx,
      isMobile,
      // pdfView
    };
  },
  methods: {
    pageLoaded(page: any) {
      this.page = page;
      this.pageReady = true;
    },

    increment() {
      this.setPage(this.page + 1);
    },

    decrement() {
      this.setPage(this.page - 1);
    },

    setPage(p: any) {
      if (!p) return;
      const input = document.querySelector(
        "#preview-page-input"
      ) as HTMLInputElement;
      if (p <= 0) {
        if (input) input.value = "1";
        this.page = 1;
      } else if (p >= this.numPages) {
        if (input) input.value = this.numPages.toString();
        this.page = this.numPages;
      } else {
        this.page = parseInt(p);
        this.pageReady = false;
      }
    },

    removeFile(index: number) {
      this.fileList.splice(index, 1);
      this.selectItemAt(0);
      this.$emit("update:files", this.fileList);
    },
    selectFile(id: string) {
      const index = this.fileList.findIndex((item: any) => item.id === id);
      this.selectItemAt(index ?? 0);
    },

    selectItemAt(index: number) {
      this.$emit("update:selectedIdx", index);
    },
  },
});
