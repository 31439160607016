
import {
  defineComponent,
  ref,
  inject,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
} from "vue";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import HeaderTitleBar from "@/components/parts/HeaderTitleBar.vue";
import Dropdown from "@/components/atomics/Dropdown.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import FilePreview from "@/components/parts/FilePreview.vue";
import PdfViewer from "@/components/atomics/pdf-viewer/PdfViewer.vue";
import CustomDatepicker from "@/components/atomics/CustomDatepicker.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import {
  ContractRepository,
  RepositoryFactory,
  TemplateRepository,
} from "@/lib/https";

import { getFilePathFromUrl } from "@/lib/utility/stringUtil";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
import { truncate } from "@/lib/utility/stringUtil";
import useSignFunctions from "@/lib/compositional-logic/useSignFunctions";
import { useStore } from "vuex";
import { TemplateInformation } from "@/models/contracts/Information";
import { ReadWriteRole, canContractUpdate } from "@/lib/utility/permission";

export default defineComponent({
  components: {
    FlatButton,
    TextButton,
    HeaderTitleBar,
    Dropdown,
    ValidationTextField,
    CustomDatepicker,
    FilePreview,
    AfterLoginPageLayout,
    PdfViewer,
    AvatarCircle,
  },
  name: "SignDone",
  props: {
    contractId: { type: String, default: "" },
    isGuest: { type: Boolean, default: false },
    isTransferSuccess: { type: String, default: "false" },
  },
  setup(props) {
    const { t } = useI18n();
    const isMobile = inject("isMobile");
    const state = reactive({
      isShowAdvanced: true,
      customField: [] as any,
      automaticOptions: [
        {
          id: "true",
          name: t("template.updateOption1"),
        },
        {
          id: "false",
          name: t("template.updateOption2"),
        },
      ],
      contractState: {} as any,
      files: [] as any,
      selectedFileIndex: 0,
      isAllSigned: false,
      canContractUpdate: false,
      canContractInformationUpdate: false,
    });

    const contractInfoState = reactive({
      contractId: "",
      contractOverview: "",
      contractConclusionDate: null,
      contractStartDate: null,
      contractEndDate: null,
      terminationNoticePeriod: "",
      isAutomaticallyUpdated: "null",
      controlNumber: "",
      transactionAmount: 0,
      freeText: "",
    });

    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const {
      getContractFullInformation,
      updateContracts,
      getGuestContractInformation,
    } = signDoneMethods();
    const { getAccessCode } = useSignFunctions();

    const showSuccessNotification = (text: string) => {
      store.commit("notification/showSuccessNotification", text);
    };

    const navigateToFolder = () => {
      router.push({
        name: "Folder",
        query: {
          folderId: state.contractState.folderId || "",
          folderTitle: state.contractState.folderTitle || "",
          status: route.params.status,
        },
      });
    };

    const editContractInformation = () => {
      const contractInformation = concludeSendContractInformation();

      const contractCustomFields = state.customField.map(
        (field: any, index: number) => ({
          contractId: state.contractState.id || "",
          order: index + 1,
          title: field.title,
          value: field.value,
        })
      );

      state.contractState.contractInformation = {
        ...contractInformation,
        transactionAmount: Number.parseInt(
          contractInformation.transactionAmount.toString(),
          10
        ),
        contractId: state.contractState.id,
      };
      state.contractState.contractCustomFields = contractCustomFields;

      delete state.contractState.zipFilePath;
      if (contractCustomFields.length <= 0)
        delete state.contractState.contractCustomFields;

      const jsonObject = JSON.stringify(state.contractState);
      const formData = new FormData();
      formData.append("data", jsonObject);

      updateContracts(formData).then((response) => {
        if (response)
          showSuccessNotification(
            `${truncate(response.title)} ${t(
              "notification.contractEditSuccess"
            )}`
          );
      });
    };

    const concludeSendContractInformation = () => {
      return TemplateInformation.prototype.toJson.call(contractInfoState);
    };

    const setContractInformation = (cloudContractInformation: any) => {
      const {
        contractId,
        contractOverview,
        terminationNoticePeriod,
        isAutomaticallyUpdated,
        controlNumber,
        freeText,
        contractConclusionDate,
        contractStartDate,
        contractEndDate,
        transactionAmount,
      } = cloudContractInformation;
      contractInfoState.contractId = contractId ?? "";
      contractInfoState.contractOverview = contractOverview ?? "";
      contractInfoState.terminationNoticePeriod = terminationNoticePeriod ?? "";
      contractInfoState.isAutomaticallyUpdated = typeof isAutomaticallyUpdated === 'boolean' ? isAutomaticallyUpdated.toString() : "null";
      contractInfoState.controlNumber = controlNumber ?? "";
      contractInfoState.freeText = freeText ?? "";

      contractInfoState.contractConclusionDate = contractConclusionDate
        ? new Date(contractConclusionDate)
        : ("" as any);
      contractInfoState.contractStartDate = contractStartDate
        ? new Date(contractStartDate)
        : ("" as any);
      contractInfoState.contractEndDate = contractEndDate
        ? new Date(contractEndDate)
        : ("" as any);

      contractInfoState.transactionAmount = transactionAmount ?? 0;
    };

    const setCustomFields = (customFields: any) => {
      state.customField = customFields.map((field: any) => ({
        ...field,
        title: field.title || "",
        value: field.value || "",
      }));
    };

    onMounted(() => {
      if (props.isTransferSuccess === "true") {
        showSuccessNotification(t("notification.updateSuccess"));
      }
    });

    onBeforeMount(async () => {
      try {
        let payload;

          const accessCode = getAccessCode(props.contractId);
          if (props.isGuest) {
            payload = await getGuestContractInformation(accessCode);
          } else
            payload = await getContractFullInformation(
              props.contractId,
              accessCode
            );

          state.contractState = { ...payload };

          setContractInformation(payload.contractInformation ?? {});

          setCustomFields(payload.contractCustomFields ?? []);

          state.isAllSigned =
            payload.contractSigners &&
            payload.countSigned === payload.contractSigners.length;
          state.files = payload.contractFiles.map((file: any) => ({
            id: file.id,
            name: file.fileName,
            path: getFilePathFromUrl(file.filePath),
            type: file.fileType,
          }));
          state.canContractUpdate = canContractUpdate(state.contractState.canUpdate, state.contractState.countSigned, state.contractState.status)
          state.canContractInformationUpdate = state.contractState.role == ReadWriteRole.READ_WRITE
      }
      catch (e: any) {
        const errorMessage = e?.data?.message || "";
        router.push({
          name: "ErrorPage",
          query: {
            error: errorMessage,
          },
        });
      }
    });

    return {
      ...toRefs(state),
      ...toRefs(contractInfoState),
      isMobile,
      getFilePathFromUrl,
      navigateToFolder,
      editContractInformation,
    };
  },
  methods: {
    download(url: string) {
      let downloadPoint = document.createElement("a");
      downloadPoint.href = url;
      downloadPoint.target = "_blank";
      downloadPoint.click();
    },
    addCustomField(): void {
      this.customField.push({ title: "", value: "" });
    },
    removeCustomField(index: number) {
      this.customField.splice(index, 1);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.fullPath.includes("/sign/details") ||
      to.fullPath.includes("/guest/contract-guest")
    ) {
      next({
        name: "ErrorPage",
        query: {
          error: this.$t("errors.errorBackToSignAgain"),
        },
      });
    } else next();
  },
});

const signDoneMethods = () => {
  const { getContractFullInformation, getGuestContractInformation } =
    RepositoryFactory.getRepository<ContractRepository>(ContractRepository);

  const { updateContracts } =
    RepositoryFactory.getRepository<TemplateRepository>(TemplateRepository);

  return {
    getContractFullInformation,
    getGuestContractInformation,
    updateContracts,
  };
};
