
import { defineComponent, ref, computed, watch, reactive, toRefs } from "vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";

const __default__ = defineComponent({
  name: "CustomDatepicker",
  components: {
    ValidationTextField,
  },
  props: {
    value: { type: Date },
    isBlack: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    scrollPos: { type: Number, default: 0 },
    isOverlay: { type: Boolean, default: false },
  },
  emits: ["update:value"],
  setup(props, { emit }) {
    const state = reactive({
      topValue: "35px",
    });
    const dateValue = computed({
      get(): Date | undefined {
        return props.value;
      },

      set(value: Date | undefined) {
        emit("update:value", value);
      },
    });

    const datepicker = ref<InstanceType<typeof ValidationTextField>>();

    const focusDatePicker = () => {
      (datepicker.value?.datePickerRef as any)?.inputRef?.focus();
      if (props.isOverlay) {
        state.topValue = `${
          datepicker.value?.$el.offsetTop + 98 - props.scrollPos
        }px`;
      }
    };

    watch(
      () => props.scrollPos,
      (val) => {
        if (!datepicker.value?.datePickerRef) return;
        datepicker.value.datePickerRef.viewShown = "none";
      }
    );

    return {
      ...toRefs(state),
      dateValue,
      datepicker,
      focusDatePicker,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "6bb7896d": (_ctx.topValue)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__