
import { computed, defineComponent, inject, ref } from "vue";
import TextButton from "@/components/atomics/TextButton.vue";

export default defineComponent({
  name: "HeaderTitleBar",
  props: {
    title: { type: String, default: "" },
    saveButtonText: { type: String, default: "" },
    onSave: { type: Function },
    onDelete: { type: Function },
    deleteButtonText: { type: String, default: "" },
    goBackButtonText: { type: String, default: "" },
  },
  components: {
    TextButton,
  },
  setup(props) {
    const haveAnyButton = computed(
      () =>
        props.saveButtonText != "" ||
        props.deleteButtonText != "" ||
        props.goBackButtonText != ""
    );
    const isMobile = inject("isMobile");

    return { haveAnyButton, isMobile };
  },
});
