import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "banner-container after-login-page-title" }
const _hoisted_2 = { class: "banner-text" }
const _hoisted_3 = {
  key: 0,
  class: "btn-group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextButton = _resolveComponent("TextButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
    (_ctx.haveAnyButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.saveButtonText != '')
            ? (_openBlock(), _createBlock(_component_TextButton, {
                key: 0,
                text: _ctx.saveButtonText,
                icon: require('icons/primary-save-outline.svg'),
                "is-show-text": !_ctx.isMobile,
                onClick: _ctx.onSave
              }, null, 8, ["text", "icon", "is-show-text", "onClick"]))
            : _createCommentVNode("", true),
          (_ctx.deleteButtonText != '')
            ? (_openBlock(), _createBlock(_component_TextButton, {
                key: 1,
                text: _ctx.deleteButtonText,
                icon: require('icons/delete.svg'),
                "is-show-text": !_ctx.isMobile,
                class: "danger-btn ml-2",
                onClick: _ctx.onDelete
              }, null, 8, ["text", "icon", "is-show-text", "onClick"]))
            : _createCommentVNode("", true),
          (_ctx.goBackButtonText != '')
            ? (_openBlock(), _createBlock(_component_TextButton, {
                key: 2,
                text: _ctx.goBackButtonText,
                icon: require('icons/prev-arrow.svg'),
                "is-show-text": !_ctx.isMobile,
                onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onBack')))
              }, null, 8, ["text", "icon", "is-show-text"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}