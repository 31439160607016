import folder from "@/locales/ja/folder";

export enum UserRole {
    OWNER = 1,
    MANAGER = 2,
    MEMBER = 3,
}

export enum ReadWriteRole {
    NO_PERMISSION = 0,
    READ_WRITE = 1,
    READ_ONLY = 2,
};

export const getReadWriteRoleName = (readWriteRole: number): string => {
    switch (readWriteRole){
        case ReadWriteRole.READ_WRITE:
            return folder.readWrite;
        case ReadWriteRole.READ_ONLY:
            return folder.readOnly;
        default:
            return "";
    }
}

export const ReadWritePermissions = [
    {
        id: ReadWriteRole.READ_WRITE,
        name: getReadWriteRoleName(ReadWriteRole.READ_WRITE),
    },
    {
        id: ReadWriteRole.READ_ONLY,
        name: getReadWriteRoleName(ReadWriteRole.READ_ONLY),
    },
];
  

export enum ContractStatus {
    NOT_CONTRACT = -1,
    DRAFT = 0,
    NEED_TO_SIGN = 1,
    WAIT_OTHER_SIGNER_SIGN = 2,
    SIGNED = 3,
    REJECTED = 4,
};

const receivedFolderId = "received-folder";

export const isOwner = (userRole: UserRole): boolean => {
    return userRole === UserRole.OWNER
}

export const hasPermission = (readWriteRole: number): boolean => {
    return readWriteRole > ReadWriteRole.NO_PERMISSION
};

export const isUnsigned = (countSigned: number): boolean => {
    return countSigned === 0
};
export const isDraft = (status: number): boolean => {
    return status === ContractStatus.DRAFT
};
export const isNeedToSign = (status: number): boolean => {
    return status === ContractStatus.NEED_TO_SIGN
};
export const isWaitingForSignature = (status: number): boolean => {
    return isNeedToSign(status) || status === ContractStatus.WAIT_OTHER_SIGNER_SIGN
};
export const isContractClosed = (status: number): boolean => {
    return status === ContractStatus.SIGNED || status === ContractStatus.REJECTED
};
export const canContractUpdate = (canUpdate: boolean, countSigned: number, status: number): boolean => {
    return canUpdate && isUnsigned(countSigned) && !isContractClosed(status)
}
export const canContractDelete = (canDelete: boolean, countSigned: number, status: number): boolean => {
    return canDelete && isUnsigned(countSigned) && !isContractClosed(status)
};

export const isReceivedFolder = (folderId: string): boolean => {
    return folderId != null && folderId.includes(receivedFolderId)
};

export const isReadWrite = (readWriteRole: number): boolean => {
    return readWriteRole === ReadWriteRole.READ_WRITE
};