import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!

  return (_openBlock(), _createBlock(_component_ValidationTextField, {
    "is-date-picker": "",
    dateValue: _ctx.dateValue,
    "onUpdate:dateValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateValue) = $event)),
    "suffix-icon": 
      _ctx.isBlack
        ? require('icons/calendar-black.svg')
        : require('icons/primary-calendar-outline.svg')
    ,
    "suffix-icon-action": _ctx.focusDatePicker,
    readonly: _ctx.readonly,
    ref: "datepicker",
    onOnFocus: _ctx.focusDatePicker
  }, null, 8, ["dateValue", "suffix-icon", "suffix-icon-action", "readonly", "onOnFocus"]))
}