export class FileInput {
  id = "";
  contractFileId?: string = undefined;
  type = 1;
  page = 1;
  isOptionalText = true;
  isStamp?: boolean = false;
  signer?: string = "";
  text = "";
  isChecked = false;
  isFilled?: boolean = false;
  contractSignerOrder = 1;
  x = 0;
  y = 0;
  w = 0;
  h = 0;
  padding = 0;
  isRoundStamp = true;
  fontSize = "10";
  minHeight = 0;
  scale = 1.0;

  constructor(
    id: string,
    contractFileId: string,
    type: number,
    page: number,
    signer: string,
    text: string,
    isChecked: boolean,
    isOptionalText: boolean,
    contractSignerOrder: number,
    x: number,
    y: number,
    w: number,
    h: number,
    padding: number,
    isRoundStamp: boolean,
    fontSize: string,
    scale: number,
    minHeight = 40
  ) {
    this.id = id;
    this.contractFileId = contractFileId;
    this.type = type;
    this.isStamp = type === 2;
    this.isOptionalText = isOptionalText;
    this.page = page;
    (this.signer = signer), (this.text = text);
    this.isChecked = isChecked;
    this.isFilled = false;
    this.contractSignerOrder = contractSignerOrder;
    this.x = x;
    this.y = y;
    this.w = w;
    this.h = h;
    this.padding = padding;
    this.isRoundStamp = isRoundStamp;
    this.fontSize = fontSize;
    this.minHeight = minHeight;
    this.scale = scale;
  }

  static getPosition(x: number, y: number, w: number, h: number, type: number) {
    const getPositionStamp = (x: number, y: number, w: number) => ({
      x: x - 10 - w / 2,
      y: y - 10,
      w: w * 2,
      h: w,
    });

    const getPositionTextField = (
      x: number,
      y: number,
      w: number,
      h: number
    ) => ({
      x,
      y,
      w,
      h,
    });

    const getPositionCheckbox = (
      x: number,
      y: number,
      w: number,
      h: number
    ) => ({
      x,
      y,
      w,
      h,
    });

    switch (type) {
      case 1:
        return getPositionTextField(x, y, w, h);
      case 2:
        return getPositionStamp(x, y, w);
      case 3:
        return getPositionCheckbox(x, y, w, h);
      default:
        return { x, y, w, h };
    }
  }
  static fromJson(inputVars: any) {
    const {
      id,
      contractFileId,
      contractSignerOrder,
      page,
      type,
      text,
      isOptionalCheckbox,
      positionX,
      positionY,
      width,
      height,
      checkedByDefault,
      imprintType,
      fontSize,
    } = inputVars;
    const { x, y, w, h } = this.getPosition(
      positionX,
      positionY,
      width,
      height,
      type
    );
    return new FileInput(
      id,
      contractFileId,
      type,
      page,
      "",
      text,
      checkedByDefault,
      isOptionalCheckbox,
      contractSignerOrder,
      x,
      y,
      w,
      h,
      10,
      imprintType === 1,
      (fontSize ?? "").toString(),
      1.0
    );
  }

  applyScale(scale: number) {
    this.w *= scale;
    this.h *= scale;
    this.x *= scale;
    this.y *= scale;
    this.padding *= scale;
    this.minHeight *= scale;
  }

  applyScaleMobile(scale: number) {
    const applyForOther = (scale: number) => {
      this.w *= scale;
      this.x *= scale;
      this.y *= scale;
    };

    const applyForStamp = (scale: number) => {
      this.w *= scale;
      this.h *= scale;
      this.x *= scale;
      this.y *= scale;
      this.padding *= scale;
    };

    switch (this.type) {
      case 1:
        applyForOther(scale);
        break;
      case 2:
        applyForStamp(scale);
        break;
      case 3:
        applyForOther(scale);
        break;
    }
  }

  updateFontSize(fontSize: string) {
    this.fontSize = fontSize;
  }

  toJson(scale: number) {
    return scale < 1 ? this.toJsonMobile(scale) : this.toJsonDesktop(scale);
  }

  toJsonMobile(scale: number) {
    const toJsonStamp = (scale: number) => {
      const diameter = Math.floor(this.w / (scale * 2));
      const positionX = Math.floor(
        (this.x + this.w / 4 + this.padding) / scale
      );
      const positionY = Math.floor((this.y + this.padding) / scale);
      return {
        page: this.page,
        type: this.type,
        text: this.text,
        contractSignerOrder:
          this.contractSignerOrder !== 0 ? this.contractSignerOrder : 1,
        checkedByDefault: false,
        isOptionalCheckbox: false,
        positionX: positionX + 1,
        positionY: positionY + 1,
        width: diameter,
        height: diameter,
        imprintType: this.isRoundStamp ? 1 : 2,
      };
    };

    const toJsonTextField = (scale: number) => {
      const width = Math.floor(this.w / scale);
      const height = Math.floor(this.h / scale);
      const positionX = Math.floor(this.x / scale);
      const positionY = Math.floor(this.y / scale);
      return {
        page: this.page,
        type: this.type,
        text: this.text,
        contractSignerOrder:
          this.contractSignerOrder !== 0 ? this.contractSignerOrder : 1,
        checkedByDefault: false,
        isOptionalCheckbox: false,
        positionX: positionX,
        positionY: positionY,
        width: width,
        height: height,
        fontSize: parseInt(this.fontSize),
      };
    };

    const toJsonCheckbox = (scale: number) => {
      const width = Math.floor(this.w / scale);
      const height = Math.floor(this.h / scale);
      const positionX = Math.floor(this.x / scale);
      const positionY = Math.floor(this.y / scale);
      return {
        page: this.page,
        type: this.type,
        text: this.text,
        contractSignerOrder:
          this.contractSignerOrder !== 0 ? this.contractSignerOrder : 1,
        checkedByDefault: this.isChecked,
        isOptionalCheckbox: this.isOptionalText,
        positionX: positionX,
        positionY: positionY,
        width: width,
        height: height,
        fontSize: parseInt(this.fontSize),
      };
    };

    switch (this.type) {
      case 1:
        return toJsonTextField(scale);
      case 2:
        return toJsonStamp(scale);
      case 3:
        return toJsonCheckbox(scale);
      default:
        return null;
    }
  }

  toJsonDesktop(scale: number) {
    const toJsonStamp = (scale: number) => {
      const diameter = Math.floor(this.w / (scale * 2));
      const positionX = Math.floor(
        (this.x + this.w / 4 + this.padding) / scale
      );
      const positionY = Math.floor((this.y + this.padding) / scale);
      return {
        page: this.page,
        type: this.type,
        text: this.text,
        contractSignerOrder:
          this.contractSignerOrder !== 0 ? this.contractSignerOrder : 1,
        checkedByDefault: false,
        isOptionalCheckbox: false,
        positionX: positionX + 1,
        positionY: positionY + 1,
        width: diameter,
        height: diameter,
        imprintType: this.isRoundStamp ? 1 : 2,
      };
    };

    const toJsonTextField = (scale: number) => {
      const width = Math.floor(this.w / scale);
      const height = Math.floor(this.h / scale);
      const positionX = Math.floor(this.x / scale);
      const positionY = Math.floor(this.y / scale);
      return {
        page: this.page,
        type: this.type,
        text: this.text,
        contractSignerOrder:
          this.contractSignerOrder !== 0 ? this.contractSignerOrder : 1,
        checkedByDefault: false,
        isOptionalCheckbox: false,
        positionX: positionX,
        positionY: positionY,
        width: width,
        height: height,
        fontSize: parseInt(this.fontSize),
      };
    };

    const toJsonCheckbox = (scale: number) => {
      const width = Math.floor(this.w / scale);
      const height = Math.floor(this.h / scale);
      const positionX = Math.floor(this.x / scale);
      const positionY = Math.floor(this.y / scale);
      return {
        page: this.page,
        type: this.type,
        text: this.text,
        contractSignerOrder:
          this.contractSignerOrder !== 0 ? this.contractSignerOrder : 1,
        checkedByDefault: this.isChecked,
        isOptionalCheckbox: this.isOptionalText,
        positionX: positionX,
        positionY: positionY,
        width: width,
        height: height,
        fontSize: parseInt(this.fontSize),
      };
    };

    switch (this.type) {
      case 1:
        return toJsonTextField(scale);
      case 2:
        return toJsonStamp(scale);
      case 3:
        return toJsonCheckbox(scale);
      default:
        return null;
    }
  }
}
