import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d7756f72"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "preview-page" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("canvas", {
      id: 'pdf-canvas' + _ctx.fileId,
      style: {"display":"inline-block","box-shadow":"0px 3px 6px rgba(2, 2, 2, 0.1)"}
    }, null, 8, _hoisted_2),
    _createElementVNode("div", {
      id: 'canvas-overlay' + _ctx.fileId
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 8, _hoisted_3)
  ]))
}