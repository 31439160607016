import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04213d42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon" }
const _hoisted_2 = { class: "icon-circle" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "icon__uploaded",
        src: _ctx.avatar,
        alt: "default user icon"
      }, null, 8, _hoisted_3)
    ])
  ]))
}