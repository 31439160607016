
import { defineComponent } from "vue";

export default defineComponent({
  name: "AvatarCircle",
  props: {
    avatar: { type: String, default: "" },
  },
});
/**
 * @vuese
 * @group Atomic/Organisms
 * IconCircle component
 */
