import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "pdf-preview" }
const _hoisted_2 = { class: "title-full mb-1" }
const _hoisted_3 = { class: "title-mobile mb-1" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "preview-area" }
const _hoisted_6 = {
  key: 1,
  class: "docx-area"
}
const _hoisted_7 = { class: "paper" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 0,
  class: "preview-controls mt-1"
}
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "mr-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown = _resolveComponent("dropdown")!
  const _component_PdfViewer = _resolveComponent("PdfViewer")!
  const _component_TextButton = _resolveComponent("TextButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass({
        'pl-2 pr-2 pt-2 pb-2': _ctx.isPadding,
      })
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.fileList[_ctx.selectedIdx]?.name), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_dropdown, {
          options: _ctx.fileList,
          "onUpdate:value": _ctx.selectFile,
          "default-option": _ctx.fileList[_ctx.selectedIdx]?.id,
          class: "dropdown",
          name: "dropdownFile"
        }, null, 8, ["options", "onUpdate:value", "default-option"]),
        (_ctx.canRemove)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "ml-1",
              src: require('icons/remove.svg'),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeFile(_ctx.selectedIdx)))
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.isPdf)
          ? (_openBlock(), _createBlock(_component_PdfViewer, {
              key: 0,
              class: "preview-page",
              src: _ctx.fileList[_ctx.selectedIdx]?.path,
              page: _ctx.page,
              onPageNums: _cache[1] || (_cache[1] = ($event: any) => (_ctx.numPages = $event)),
              onPageLoaded: _ctx.pageLoaded,
              "scale-fit-with": _ctx.isScale || _ctx.isMobile
            }, null, 8, ["src", "page", "onPageLoaded", "scale-fit-with"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("img", {
                  src: require('icons/docx.svg'),
                  alt: "docx icon",
                  class: "icon"
                }, null, 8, _hoisted_8)
              ])
            ]))
      ]),
      (_ctx.isPdf)
        ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
            _createElementVNode("span", null, [
              _createElementVNode("input", {
                type: "text",
                value: _ctx.page,
                id: "preview-page-input",
                onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setPage($event.target.value))),
                class: _normalizeClass({ 'big-input': _ctx.page > 9 })
              }, null, 42, _hoisted_10),
              _createElementVNode("span", _hoisted_11, "/" + _toDisplayString(_ctx.numPages), 1)
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_TextButton, {
                class: "icon-button",
                icon: require('icons/bold-prev-arrow.svg'),
                "disabled-icon": require('icons/prev-arrow-grey.svg'),
                onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.pageReady ? _ctx.decrement() : '')),
                enabled: _ctx.page >= 2
              }, null, 8, ["icon", "disabled-icon", "enabled"]),
              _createVNode(_component_TextButton, {
                class: "icon-button",
                "right-icon": require('icons/next-arrow.svg'),
                "disabled-icon": require('icons/next-arrow-grey.svg'),
                onOnClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.pageReady ? _ctx.increment() : '')),
                enabled: _ctx.page != _ctx.numPages
              }, null, 8, ["right-icon", "disabled-icon", "enabled"])
            ])
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}