import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "selected-text"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "custom-options" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input custom-select-wrapper", {
      'custom-select-wrapper-small': _ctx.isSmallDropdown,
      readonly: _ctx.readonly,
    }]),
    onClick: _cache[1] || (_cache[1] = ($event: any) => (!_ctx.readonly ? _ctx.openDropdown() : null)),
    ref: "dropdown"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["custom-select__trigger", {
        'custom-select-small__trigger': _ctx.isSmallDropdown,
        readonly: _ctx.readonly,
      }])
    }, [
      (_ctx.placeHolder == _ctx.selectedText)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass({ 'placeholder-text': _ctx.placeHolder != '' })
          }, _toDisplayString(_ctx.selectedText), 3))
        : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.selectedText), 1)),
      _createElementVNode("img", {
        class: "icon",
        src: require('icons/icon_dropdown.svg')
      }, null, 8, _hoisted_2)
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: option.id,
          class: _normalizeClass(["custom-option", {
          selected: option.id == _ctx.selectedId,
          'disabled-dropdown': option.disabled,
        }]),
          onClick: ($event: any) => (!option.disabled && _ctx.onValueChanged(index)),
          "data-value": "option"
        }, _toDisplayString(option?.name), 11, _hoisted_4))
      }), 128))
    ]),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedId) = $event)),
      name: _ctx.name,
      style: { display: 'none' }
    }, null, 8, _hoisted_5), [
      [_vModelText, _ctx.selectedId]
    ])
  ], 2))
}